<template>
  <div class="skeletonappdetails">
    <div class="infodiv">
      <Divider align="left">
        <div class="p-d-inline-flex p-ai-center">
          <i class="fas fa-info p-mr-2"></i>
          <b>Info</b>
        </div>
      </Divider>
      <div class="p-grid p-pl-6 p-pr-6">
        <div class="titlediv p-col">
          <Skeleton height="1rem" class="p-mb-2"></Skeleton>
          <Skeleton height="1rem"></Skeleton>
        </div>
        <div class="apptype p-col">
          <Skeleton height="1rem" class="p-mb-2"></Skeleton>
          <Skeleton height="1rem"></Skeleton>
        </div>
        <div class="appstage p-col">
          <Skeleton height="1rem" class="p-mb-2"></Skeleton>
          <Skeleton height="1rem"></Skeleton>
        </div>
        <div class="appstage p-col">
          <Skeleton height="1rem" class="p-mb-2"></Skeleton>
          <Skeleton height="1rem"></Skeleton>
        </div>
      </div>
    </div>
    <div class="ownersdiv">
      <Divider align="left">
        <div class="p-d-inline-flex p-ai-center">
          <i class="fas fa-user-shield p-mr-2"></i>
          <b>Owners</b>
        </div>
      </Divider>
      <div class="p-grid p-pl-6 p-pr-6">
        <div class="p-col">
          <Skeleton height="1rem" class="p-mb-2"></Skeleton>
          <Skeleton height="1rem"></Skeleton>
        </div>
        <div class="p-col">
          <Skeleton height="1rem" class="p-mb-2"></Skeleton>
          <Skeleton height="1rem"></Skeleton>
        </div>
        <div class="p-col">
          <Skeleton height="1rem" class="p-mb-2"></Skeleton>
          <Skeleton height="1rem"></Skeleton>
        </div>
        <div class="p-col">
          <Skeleton height="1rem" class="p-mb-2"></Skeleton>
          <Skeleton height="1rem"></Skeleton>
        </div>
      </div>
      <div></div>
    </div>
    <div class="desdiv">
      <Divider align="left">
        <div class="p-d-inline-flex p-ai-center">
          <i class="fas fa-receipt p-mr-2"></i>
          <b>Description</b>
        </div>
      </Divider>
      <div class="p-grid p-pl-6 p-pr-6">
        <Skeleton height="7rem"></Skeleton>
      </div>
    </div>
    <div class="tagsdiv">
      <Divider align="left">
        <div class="p-d-inline-flex p-ai-center">
          <i class="fas fa-tags p-mr-2"></i>
          <b>Tags & Groups</b>
        </div>
      </Divider>
      <div class="p-grid p-pl-6 p-pr-6">
        <div class="tagsdiv p-col">
          <Skeleton height="1rem" class="p-mb-2"></Skeleton>
          <div class="p-d-flex">
            <div class="p-mr-2">
              <Skeleton height="1rem" width="10rem"></Skeleton>
            </div>
            <div class="p-mr-2">
              <Skeleton height="1rem" width="10rem"></Skeleton>
            </div>
            <div class="p-mr-2">
              <Skeleton height="1rem" width="10rem"></Skeleton>
            </div>
          </div>
        </div>
        <div class="groupsdiv p-col">
          <Skeleton height="1rem" class="p-mb-2"></Skeleton>
          <div class="p-d-flex">
            <div class="p-mr-2">
              <Skeleton height="1rem" width="10rem"></Skeleton>
            </div>
            <div class="p-mr-2">
              <Skeleton height="1rem" width="10rem"></Skeleton>
            </div>
            <div class="p-mr-2">
              <Skeleton height="1rem" width="10rem"></Skeleton>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="linksdiv">
      <Divider align="left">
        <div class="p-d-inline-flex p-ai-center">
          <i class="fas fa-link p-mr-2"></i>
          <b>Links</b>
        </div>
      </Divider>
      <div class="p-grid p-pl-6 p-pr-6 p-pt-3 p-pb-3">
        <div class="p-d-flex">
          <div class="p-mr-2">
            <Skeleton height="1rem" width="10rem"></Skeleton>
          </div>
          <div class="p-mr-2">
            <Skeleton height="1rem" width="10rem"></Skeleton>
          </div>
          <div class="p-mr-2">
            <Skeleton height="1rem" width="10rem"></Skeleton>
          </div>
          <div class="p-mr-2">
            <Skeleton height="1rem" width="10rem"></Skeleton>
          </div>
          <div class="p-mr-2">
            <Skeleton height="1rem" width="10rem"></Skeleton>
          </div>
        </div>
      </div>
    </div>
    <div class="envdiv">
      <Divider align="left">
        <div class="p-d-inline-flex p-ai-center">
          <i class="fas fa-tags p-mr-2"></i>
          <b>Environments</b>
        </div>
      </Divider>
      <div class="p-grid p-pl-6 p-pr-6">
        <div class="tagsdiv p-col">
         <div class="p-mb-3">
            <Skeleton height="1rem" class="p-mb-2"></Skeleton>
            <Skeleton height="1rem" class="p-mb-2"></Skeleton>
         </div>
         <div class="p-mb-3">
            <Skeleton height="1rem" class="p-mb-2"></Skeleton>
            <Skeleton height="1rem" class="p-mb-2"></Skeleton>
         </div>
        <div class="p-d-flex">
            <div class="p-mr-2">
              <Skeleton height="1rem" width="10rem"></Skeleton>
            </div>
            <div class="p-mr-2">
              <Skeleton height="1rem" width="10rem"></Skeleton>
            </div>
            <div class="p-mr-2">
              <Skeleton height="1rem" width="10rem"></Skeleton>
            </div>
          </div>
        </div>
        <div class="groupsdiv p-col">
         <div class="p-mb-3">
            <Skeleton height="1rem" class="p-mb-2"></Skeleton>
            <Skeleton height="1rem" class="p-mb-2"></Skeleton>
         </div>
         <div class="p-mb-3">
            <Skeleton height="1rem" class="p-mb-2"></Skeleton>
            <Skeleton height="1rem" class="p-mb-2"></Skeleton>
         </div>
          <div class="p-d-flex">
            <div class="p-mr-2">
              <Skeleton height="1rem" width="10rem"></Skeleton>
            </div>
            <div class="p-mr-2">
              <Skeleton height="1rem" width="10rem"></Skeleton>
            </div>
            <div class="p-mr-2">
              <Skeleton height="1rem" width="10rem"></Skeleton>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="versionsdiv">
      <Divider align="left">
        <div class="p-d-inline-flex p-ai-center">
          <i class="fas fa-link p-mr-2"></i>
          <b>Versions</b>
        </div>
      </Divider>
      <div class="p-grid p-pl-6 p-pr-6 p-pt-3 p-pb-3">
        <div class="p-d-flex">
          <div class="p-mr-2">
            <Skeleton height="1rem" width="10rem"></Skeleton>
          </div>
          <div class="p-mr-2">
            <Skeleton height="1rem" width="10rem"></Skeleton>
          </div>
          <div class="p-mr-2">
            <Skeleton height="1rem" width="10rem"></Skeleton>
          </div>
          <div class="p-mr-2">
            <Skeleton height="1rem" width="10rem"></Skeleton>
          </div>
          <div class="p-mr-2">
            <Skeleton height="1rem" width="10rem"></Skeleton>
          </div>
        </div>
      </div>
    </div>
    <div class="commentdiv">
      <Divider align="left">
        <div class="p-d-inline-flex p-ai-center">
          <i class="fas fa-user-shield p-mr-2"></i>
          <b>Discussion</b>
        </div>
      </Divider>
      <div class="p-grid p-pl-6 p-pr-6">
       <div class="p-col-8">
            <Skeleton height="1rem" class="p-mb-2"></Skeleton>
            <Skeleton height="1rem" class="p-mb-2"></Skeleton>
            <Skeleton height="1rem" class="p-mb-2"></Skeleton>
            <Skeleton height="1rem" class="p-mb-2"></Skeleton>
            <Skeleton height="1rem" class="p-mb-2"></Skeleton>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "skeletonAppDetails",
  created() {},
  components: {},
  methods: {},
  props: {},
  watch: {},
  data() {
    return {};
  },
};
</script>
<style scoped>
</style>