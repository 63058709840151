<template>
  <div class="card  p-p-3" id="appDetails">
  <div class="p-d-flex p-jc-between">
      <div class="p-mr-2">
        <b-breadcrumb :items="breadcrumb_items"></b-breadcrumb>
      </div>
      <div class="p-col-1-cust" v-if="isUserAdmin">
          <a :href="`/app/${appId}/edit`" class="p-mr-2" style="text-decoration:none">
          <Button label="Edit Application" icon="fas fa-edit" class="p-button-secondary iconmargin"/>
         </a>
          <a href="/createapp" style="text-decoration:none">
          <Button label="Create Application" icon="fas fa-edit" class="p-button-secondary iconmargin"/>
         </a>     
      </div>
  </div>
    <!-- <span class="p-fluid">
    <AutoComplete v-model="selectedapp" :suggestions="filteredApps" @complete="searchAppsByName($event)" field="title">
    <template #item="slotProps">
      <div class="p-d-flex">
        <div class="p-mr-6">
          <span class="p-mr-2"><i class="fas fa-user"></i></span>
          <label> Name:</label>{{ slotProps.item.title }}
        </div>
        <div class="p-mr-6">
          <span class="p-mr-2"><i class="fas fa-envelope-open-text"></i></span>
           <label> App Type:</label> {{ slotProps.item.app_type }}
        </div>
        <div>
          <span class="p-mr-2"><i class="fas fa-envelope-open-text"></i></span>
           <label> Stage:</label>  {{ slotProps.item.stage }}
        </div>
      </div>
    </template>
    </AutoComplete>
    </span> -->
    <div v-if="isLoading">
      <SkeletonAppDetails/>
    </div>
    <div class="createapp" v-else>
      <div v-if="appDetails">
          <div class="infodiv">
            <Divider align="left">
              <div class="p-d-inline-flex p-ai-center">
                <i class="fas fa-info p-mr-2"></i>
                <b>Info</b>
              </div>
            </Divider>
            <div class="p-grid p-pl-6 p-pr-6 appbgdiv">
                <div class="titlediv p-col">
                    <div class="floatlabel">Title</div>
                  <div class="labelval">{{ appDetails.title }}</div>
                </div>
                <div class="apptype p-col">
                  <div class="floatlabel">Application Type</div>
                  <div class="labelval">{{ appDetails.app_type }}</div>
                </div>
                <div class="appstage p-col">
                  <div class="floatlabel">Stage</div>
                  <div class="labelval">{{ appDetails.stage }}</div>
                </div>
                <div class="appstage p-col">
                </div>
              </div>
          </div>
          <div class="ownersdiv">
            <Divider align="left">
              <div class="p-d-inline-flex p-ai-center">
                <i class="fas fa-user-shield p-mr-2"></i>
                <b>Owners</b>
              </div>
            </Divider>
            <div class="p-grid p-pl-6 p-pr-6 appbgdiv">
              <div class="p-col">
                  <div class="floatlabel"> Business Owner Email Id </div>
                  <div class="labelval">{{ getuserEmail(appDetails.business_owner) }}</div>
                </div>
                <div class="p-col">
                  <div class="floatlabel"> Business Owner Name </div>
                  <div class="labelval">{{ appDetails.business_owner_name }}</div>
                </div>
              <div class="p-col">
                  <div class="floatlabel">App Owner 1 </div>
                  <div class="labelval">{{ getuserName(appDetails.app_owner1) }}</div>
              </div>
              <div class="p-col">
                  <div class="floatlabel">App Owner 2 </div>
                  <div class="labelval">{{ getuserName(appDetails.app_owner2) }}</div>
              </div>
                <div class="p-col">
                  <div class="floatlabel">App Owner 3 </div>
                  <div class="labelval">{{ getuserName(appDetails.app_owner3) }}</div>
              </div>
            </div>
            <div></div>
          </div>
          <div class="desdiv">
            <Divider align="left">
              <div class="p-d-inline-flex p-ai-center">
                <i class="fas fa-receipt p-mr-2"></i>
                <b>Description</b>
              </div>
            </Divider>
            <div class="p-grid p-pl-6 appbgdiv">
              <div v-html="appDetails.description" class="p-m-1"></div>
          </div>
          </div>
          <div class="tagsdiv">
            <Divider align="left">
              <div class="p-d-inline-flex p-ai-center">
                <i class="fas fa-tags p-mr-2"></i>
                <b>Tags & Groups</b>
              </div>
            </Divider>
            <div class="p-grid p-pl-6 p-pr-6 appbgdiv">
              <div class="tagsdiv p-col-6">
                  <div class="floatlabel">Tags</div>
                  <div v-if="appDetails.tags && appDetails.tags.length">
                  <div class="p-grid">
                    <div v-for="(tag, index)  of appDetails.tags"  :key="index" class="p-col-6 p-mt-1 p-mb-1 labelval">  
                      <Chip :label="tag" icon="fas fa-tag" class="p-mr-2" />
                      </div>
                  </div>
              </div>
              <div v-else>No App Tags</div>
            </div>
              <div class="groupsdiv p-col-6">
                  <div class="floatlabel">Related Groups</div>
                  <div v-if="appDetails.related_groups && appDetails.related_groups.length">
                  <div class="p-grid p-m-1">
                      <div v-for="(tag, index)  of appDetails.related_groups"  :key="index" class="p-col-3 labelval">  
                        <Chip :label="tag" icon="fas fa-object-group" class="p-mr-2" />
                        </div>
                    </div>
                  </div>
                <div v-else>No Related Groups</div>
              </div>
            </div>
          </div>
          <div class="linksdiv">
            <Divider align="left">
              <div class="p-d-inline-flex p-ai-center">
                <i class="fas fa-link p-mr-2"></i>
                <b>Links</b>
              </div>
            </Divider>
            <div class="p-grid p-pl-6 p-pr-6 p-pt-3 p-pb-3 appbgdiv">
                <div v-if="appDetails.links && appDetails.links.length">
                  <div class="p-d-flex p-m-1">
                    <div v-for="(link, index)  of appDetails.links"  :key="index">  
                      <Chip class="p-mr-2">
                        <a target="_blank" v-bind:href="link.url" class="achip"><i class="fas fa-external-link-alt"></i> {{link.title}} </a>
                      </Chip>
                      </div>
                  </div>
                </div>
                <div v-else>No App Links</div>
            </div>
          </div>
          <div class="envdiv">
            <Divider align="left">
              <div class="p-d-inline-flex p-ai-center">
                <i class="fas fa-window-maximize p-mr-2"></i>
                <b>Environments</b>
              </div>
            </Divider>
            <div class="p-grid p-pl-6 p-pr-6 appbgdiv"  v-if="appDetails.environments && appDetails.environments.length">
            <div  class="p-col p-m-1" v-for="(env, index) in appDetails.environments" :key="index">
                  <div class="p-mb-3">
                    <div class="floatlabel mb-2">{{ env.name }}</div>
                      <Chip class="p-mr-2" :title="env.url">
                      <a target="_blank" v-bind:href="env.url" class="achip"><i class="fas fa-external-link-alt"></i> Website </a>
                    </Chip>
                  </div>
                  <div class="p-mb-3" v-if="env.title">
                      <label for="env-title" class="floatlabel">Title</label>
                      <div class="mb-2">{{ env.title }}</div>
                  </div>
                  <div class="p-mb-3" v-if="env.description">
                    <label for="app-env-description" class="floatlabel">Description</label>
                    <div v-html="env.description"></div>
                  </div>
                  <div class="p-mb-3">
                    <div class="p-grid">
                      <div v-for="(link, index) in env.links" :key="index" class="mt-2 mr-2 applink p-col-4">
                      <Chip class="p-mr-2" :title="link.description">
                        <a target="_blank" v-bind:href="link.url" class="achip"><i class="fas fa-external-link-alt"></i> {{ link.title }} </a>
                      </Chip>
                      </div>
                    </div>
                  </div>
            </div>
            </div>
            <div v-else class="p-pl-6 p-pr-6">No App Environments</div>
          </div>
          <div class="versionsdiv">
            <Divider align="left">
              <div class="p-d-inline-flex p-ai-center">
                <i class="fas fa-tags p-mr-2"></i>
                <b>Versions</b>
              </div>
            </Divider>
            <div class="p-grid p-pl-6 p-pr-6 appbgdiv">
              <div v-if="appDetails.versions && appDetails.versions.length">
                <div v-for="(version, index) in appDetails.versions" :key="index" class="p-grid p-m-1">
                  <div class="appversion">
                      <div class="p-mb-3">
                        <div for="version-start-date" class="floatlabel">Title</div>
                        <span>{{ version.title }}</span>
                      </div>
                    <div class="p-mb-3">
                    <label for="app-version-description" class="floatlabel">Description</label>
                    <div v-html="version.description"></div>
                  </div>
                      <div class="p-mb-3">
                        <div for="version-start-date" class="floatlabel">Start Date</div>
                        <div>{{ version.start_date }}</div>
                      </div>
                      <div class="p-mb-3">
                        <div for="version-go-live-date" class="floatlabel">Go Live Date</div>
                        <div>{{ version.go_live_date }}</div>
                      </div>
                  </div>
                </div>
              </div>
              <div v-else>No App Versions</div>
            </div>
          </div> 
          <div class="ownersdiv">
            <Divider align="left">
              <div class="p-d-inline-flex p-ai-center">
                <i class="fas fa-user-shield p-mr-2"></i>
                <b>Last modified information</b>
              </div>
            </Divider>
            <div class="p-grid p-pl-6 p-pr-6 appbgdiv">
              <div class="p-col">
                  <div class="floatlabel"> Last modified By </div>
                  <div class="labelval">{{ getuserEmail(appDetails.updated_by) }}</div>
                </div>
                <div class="p-col">
                  <div class="floatlabel"> Last modified On </div>
                  <div class="labelval">{{ appDetails.updated_on  | formatDateTimeMT}}</div>
                </div>
            </div>
            <div></div>
          </div>
          <div class="commentsdiv">
            <Divider align="left">
              <div class="p-d-inline-flex p-ai-center">
                <i class="fas fa-comments p-mr-2"></i>
                <b>Comments</b>
              </div>
            </Divider>
            <div class="appbgdiv">
              <div v-if="appDetails.comments && appDetails.comments.length" >
              <ul class="timelineul p-m-5 ">
                  <li v-for="(appComment, index) in appDetails.comments" :key="index" class="timelineli">
                    <div  class="d-flex flex-column">
                      <div v-html="appComment.comment"></div>
                      <div class="p-d-flex">
                        <p class="p-mr-3 fsmall"> By {{ appComment.created_by }} at {{ appComment.created_at | formatDateTimeMT }}</p>
                      </div>
                    </div>
                  </li>
            </ul>
              </div>
            <div v-else class="p-pl-6 p-pr-6">No App Comments</div>
            </div>
           </div>
       </div>
    </div> 
  </div>
</template>
<script>
import SkeletonAppDetails from "@/components/Shared/SkeletonAppDetails.vue";
export default {
  name: "appDetailsComponent",
  created() {
    this.appId = this.$route.params.appid;
    this.getAppById();
  },
  computed: {
    userInitials() {
      return this.$store.getters["login/getUserInitials"];
    },
    isUserAdmin() {
      let loggedUser = this.$store.getters["login/getUser"];
        return loggedUser.isUserAdmin || false;
    },
    isUserSuperAdmin() {
      let loggedUser = this.$store.getters["login/getUser"];
        return loggedUser.isUserSuperAdmin || false;
    }
  },
  components: {SkeletonAppDetails},
  methods: {
    async getAppById() {
      try {
        let _appId = this.appId;
        let result = await this.$store.dispatch("app/getAppById", _appId);
        this.appDetails = result.data;
        setTimeout(() =>{
            this.isLoading=false;
          },2000)
      } catch (error) {
        console.warn(error);
      }
    },
    async searchAppsByName(app){
        try {
        let _appname= app.query;
        let result = await this.$store.dispatch("app/getAppsByName", _appname);
        this.filteredApps= result.data;
      } catch (error) {
        console.warn(error);
      }    
    }
  },
  props: {},
  watch: {},
  data() {
    return {
      appId: null,
      appDetails: null,
      filteredApps:null,
      selectedapp:null,
      isLoading:true,
      breadcrumb_items: [
        {
          text: "Home",
          to: { name: "apps" },
        },
        {
          text: "App Details",
          active: true,
        },
      ],
    };
  },
};
</script>
<style scoped>
.achip{
    line-height: 1.5;
    margin-top: 0.2145rem;
    margin-bottom: 0.2145rem;
    color:black !important
}
.floatlabel {
  border-radius: 2px;
  font-weight: 700;
  font-size: 13px !important;
}
.appedit{
font-size: 15px;
}
.fsmall{
font-size: 11px !important;
padding-top: 8px !important;
}
.appbgdiv{
  background-color: #f8f9fa !important;
  padding: 1% !important;
  margin:1% !important
}
.labelval {
    font-size: 12px;
    margin-top: 5px;
}
</style>